<template >
    <div>
        <b-overlay :show="loadingFxStatistics" rounded="sm" no-fade>
          <b-card class="card-transaction statistic-cards" :title="$t('dashboard.fx_statistics')">
            <b-card-body>
              <div class="transaction-item">
                <b-col cols="4">
                <b-media no-body >
                  <b-media-aside>
                    <b-avatar rounded size="42" variant="primary" style="background-color: silver !important;">
                      <feather-icon size="18" icon="BarChart2Icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="text-center">
                    <h6 class="transaction-title">
                      <b>{{ fxCount ? fxCount.orderCount : 0}}</b>
                    </h6>
                    <hr style="margin:3px 0px;">
                    <small style="color: silver;">{{ $t("dashboard.fx_dashboard.order") }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
                <b-col cols="4">
                <b-media no-body >
                  <b-media-aside>
                    <b-avatar rounded size="42" variant="primary" style="background-color: paleturquoise !important;">
                      <feather-icon size="18" icon="BarChart2Icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="text-center">
                    <h6 class="transaction-title">
                      <b>{{ fxCount ?  fxCount.executionCount  : 0}}</b>
                    </h6>
                    <hr style="margin:3px 0px;">
                    <small style="color: paleturquoise;">{{ $t("dashboard.fx_dashboard.execution") }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
                <b-col cols="4">
                <b-media no-body >
                  <b-media-aside>
                    <b-avatar rounded size="42" variant="primary" style="background-color: springgreen !important;">
                      <feather-icon size="18" icon="BarChart2Icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="text-center">
                    <h6 class="transaction-title">
                      <b>{{ fxCount ? fxCount.verifyCount  : 0}}</b>
                    </h6>
                    <hr style="margin:3px 0px;">
                    <small style="color:springgreen">{{ $t("dashboard.fx_dashboard.verify") }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              </div>
            </b-card-body>
            <b-card-body>
              <div class="transaction-item">
                <b-col cols="4">
                <b-media no-body >
                  <b-media-aside>
                    <b-avatar rounded size="42" variant="primary" style="background-color: mediumpurple !important;">
                      <feather-icon size="18" icon="BarChart2Icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="text-center">
                    <h6 class="transaction-title">
                      <b>{{ fxCount ? fxCount.journalCount  : 0}}</b>
                    </h6>
                    <hr style="margin:3px 0px;">
                    <small class="text-nowrap" style="color: mediumpurple;">{{ $t("dashboard.fx_dashboard.journal") }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
                <b-col cols="4">
                <b-media no-body >
                  <b-media-aside>
                    <b-avatar rounded size="42" variant="primary" style="background-color: rgb(241 235 0) !important;">
                      <feather-icon size="18" icon="BarChart2Icon" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body class="text-center">
                    <h6 class="transaction-title">
                      <b>{{ fxCount ?  fxCount.reversalInitiatedCount  : 0}}</b>
                    </h6>
                    <hr style="margin:3px 0px;">
                    <small class="text-nowrap" style="color: rgb(241 235 0);">{{ $t("dashboard.fx_dashboard.revers_initiated") }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
                <b-col cols="4">
                <b-media no-body >
                  <b-media-aside>
                    <b-avatar rounded size="42" variant="primary" style="background-color: coral !important;">
                      <feather-icon size="18" icon="BarChart2Icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="text-center">
                    <h6 class="transaction-title">
                      <b>{{ fxCount ?  fxCount.reversedCount  : 0}}</b>
                    </h6>
                    <hr style="margin:3px 0px;">
                    <small style="color:coral">{{ $t("dashboard.fx_dashboard.reversed") }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
                

              </div>
            </b-card-body>

            <div >
              <span class="text-muted ">Total Trades: {{ fxCount ? fxCount.totalEntries  : 0}}</span>
            </div>
            
          </b-card>
        </b-overlay>
    </div>
</template>


<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";

import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import dashboardStoreModule from "./dashboardStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,

    ToastificationContent,
  },

  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = "dashboard";
    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME))
      store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, dashboardStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME))
        store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME);
    });
  },
  mounted() {
  },

  created() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },

  destroyed() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
  },

  props: {
    fxCount: {
      type: Object,
      default:  {
        cancelled: 0,
        executionCount : 0,
        journalCount: 0,
        orderCount: 0,
        reversalInitiatedCount: 0,
        reversedCount: 0,
        totalEntries: 0,
        verifyCount: 0
      },

    },
  },

  data() {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    return {
      dateNow,
      timeLineArray: [],
      formShow: false,
      loadingFxStatistics: false,
      seeFxStatistics: false,
    
    };
  },

  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },

  methods: {
    kFormatter,

    dateFormat(val) {
      return moment(val).format("DD-MM-YYYYY HH:mm");
    },

  },
};
</script>