import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    
    //Search all Equity&bonds data
    getAllStatisticsData(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAllStatisticsData`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Search all Fx execute data
 
    
  },
};
