<template>
  <div>
    <b-row class="justify-content-center">
        <b-col cols="12" md="12" lg="12">
          <div
            v-show="userDataInfo != null"
            style="font-size: 1.4em;"
            class="mt-2 text-center bg-gradient-dark p-2 text-white"
          >
            Hello
            <strong
              >{{ userDataInfo ? userDataInfo.name : "N/A" }}
              {{ userDataInfo ? userDataInfo.surname : "N/A" }}</strong
            >, welcome to Swiss Finance Corporation Trading Portal.

            <h3 class="muted mt-2 text-white">{{ dateNow }}</h3>
          </div>
        </b-col>
      </b-row>
    <b-row v-if="$Can('homepage_stats')" class="mt-2">
      <b-col cols="12" sm="12" md="6" lg="6" xl="6">
        <EquityStatistics
        :equity-bonds-count = equityCount

        ref="refEquityStatistics"
        class="animate__animated animate__fadeIn"
      />
      
      </b-col>

      <b-col cols="12" sm="12" md="6" lg="6" xl="6">
        <BondStatistics
        :bond-count = bondCount

        ref="refBondStatistics"
        class="animate__animated animate__fadeIn"
      />
      
      </b-col>

      <b-col cols="12" sm="12" md="6" lg="6" xl="6">
        <FxStatistics
        :fx-count = fxCount

        ref="refFxStatistics"
        class="animate__animated animate__fadeIn"
      />
      </b-col>

      <b-col cols="12" sm="12" md="6" lg="6" xl="6">
        <TrasfersStatistics
        :transfer-count = transferCount
        ref="refTrasfersStatistics"
        class="animate__animated animate__fadeIn"
      />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";

import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import homeStoreModule from "./homeStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "animate.css";
import EquityStatistics from "./dashboard/EquityStatistics.vue"
import FxStatistics from './dashboard/FxStatistics.vue'
import TrasfersStatistics from './dashboard/TransfersStatistics.vue'
import BondStatistics from "./dashboard/BondStatistics.vue";


export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,

    ToastificationContent,
    EquityStatistics,
    FxStatistics,
    TrasfersStatistics,
    BondStatistics
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = "home";
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME))
      store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
        store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },
  mounted() {
  },

  created() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    if(this.$Can('homepage_stats')){
      this.getAllStatisticsData();
    }
    this.checkUser();

  },

  destroyed() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
  },

  data() {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    return {
      dateNow,
      timeLineArray: [],
      formShow: false,
      equityCount : null,
      bondCount:null,
      fxCount : null,
      transferCount : null,
      userDataInfo: null,
      
    };
  },

  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },

  methods: {
    kFormatter,

    checkUser() {
      this.formShow = true;

      this.userId = parseInt(this.userData.id);

      store
        .dispatch("home/fetchUser", { id: this.userId })
        .then((response) => {
          this.userDataInfo = response.data;

          if (response.data.status != "A") {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(
              useJwt.jwtConfig.storageRefreshTokenKeyName
            );
            localStorage.removeItem("userData");
            router.push({ name: "auth-login" });
          } else {
            this.formShow = false;
          }
        })
        .catch((error) => {});
    },


    dateFormat(val) {
      return moment(val).format("DD-MM-YYYYY HH:mm");
    },

    getAllStatisticsData() {
      this.$checkAccessRight("EquityBond", "Search").then((res) => {
        if (res.data.valid) {
          this.seeEquityStatistics = true;
          store
            .dispatch("dashboard/getAllStatisticsData", {
              auth: this.user,
            })
            .then((res) => {
              this.equityCount = res.data.equity
              this.bondCount = res.data.bond
              this.fxCount = res.data.fx
              this.transferCount = res.data.transfer
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.seeEquityStatistics = false;
        }
      });
    },

  },
};
</script>

<style>
.statistic-cards .card-body .card-title {
    text-decoration: underline;
    
}

.welcome-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

</style>
